:root {
  --primary-color: #2F2235;
  --secondary-color: #60495A;
  --background-color: #f8f8f8;
  --text-color: #333333;
  --text-light: #ffffff;
  --border-color: #e0e0e0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  /* margin-left: 250px; Width of the open sidebar */
  padding: 0;
  transition: margin-left 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* When sidebar is closed */
.sidebar.closed + .main-content {
  margin-left: 50px;
}

/* PrimeReact overrides */
.p-button.p-button-primary {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.p-button.p-button-primary:hover {
  background: #e06010;
  border-color: #e06010;
}

.p-fileupload-choose {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.p-fileupload-choose:hover {
  background: #3F3244;
  border-color: #3F3244;
}

.p-fileupload-file-badge {
  background-color: var(--secondary-color);
  color: var(--text-light);
}
.p-fileupload-buttonbar{
  @apply dark:bg-[#2a2a40]
}
.p-fileupload-content{
  @apply dark:bg-[#2a2a40]
}
.p-fileupload-filename{
  @apply dark:text-white
}

.p-fileupload-filename span{
  @apply dark:text-white
}